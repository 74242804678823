<template>
  <div class="ip_dn_info">
    <div class="head">I/P, D/N</div>
    <div class="body">
      <div class="inner" v-for="(item, index) in ipDnLatest" :key="index">
        <div class="user">
          <figure class="avatar table"><img :src="item.updateUserIcon"></figure>
          <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
            <div slot="content">{{item.updateUserName}}</div>
            <div class="from_name">{{item.updateUserName}}</div>
          </el-tooltip>
        </div>
        <div class="info">
          <div class="badge">
            <span v-show="item.docType === 'IP'">I/P</span>
            <span v-show="item.docType === 'DN'">D/N</span>
          </div>
          <div class="detail">
            <div>I/P No : {{item.insurancePolicyNo}}</div>
            <div>Branch No : {{item.insurancePolicyBranchNo}}</div>
            <div>{{getType(item)}}</div>
          </div>
        </div>
        <div class="right">
          <div class="permit_download">
            <!-- <figure @click="txtDownload"><img src="@/assets/images/icons/icon_cloud-download.svg"></figure> -->
            <figure v-if="item.attachmentFiles && item.attachmentFiles.length" class="pdf" @click="attachDownload(item)"><img src="@/assets/images/icons/icon_attach_file.svg"></figure>
          </div>
          <div v-if="isFromPic" class="delete" @click="removeIpDn(item)">
            <img class="close_times" src="@/assets/images/icons/times_gray600.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { IP_DN_TYPE_VARIABLES } from 'lib-tw-common';

export default {
  name: 'TwIpDnLinking',
  inject: ['s'],
  components: {},
  props: {
    ipDnLatest: {
      type: Array,
      default: () => []
    },
    isFromPic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    removeIpDn(row) {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to unlink the pool data?')
        .then(() => {
          this.$emit('remove-ip-dn', row);
        })
        .catch(() => {});
    },
    getType(item) {
      if (item.docType === 'DN') {
        const targetIp = _.find(_.get(this, 's.cloneItems.linkageMarineInsurance.ippools'), ip => {
          const ipPolicy = ip.policyGrp;
          const ipDnTypeDn = ipPolicy.ipDnType > 9 ? Number(String(ipPolicy.ipDnType).slice(1, 2)) : null;
          return ipDnTypeDn && ipDnTypeDn === item.ipDnType && ipPolicy.senderCompanyId === item.senderCompanyId && ipPolicy.insurancePolicyNo === item.insurancePolicyNo && ipPolicy.insurancePolicyBranchNo === item.insurancePolicyBranchNo;
        });
        if (targetIp) {
          const targetVal = Number(String(targetIp.policyGrp.ipDnType).slice(0, 1));
          const ipType = _.get(_.find(IP_DN_TYPE_VARIABLES, { code: targetVal }), 'label', '');
          const dnType = _.get(_.find(IP_DN_TYPE_VARIABLES, { code: item.ipDnType }), 'label', '');
          return ipType ? `${ipType} / ${dnType}` : dnType;
        } else {
          return _.get(_.find(IP_DN_TYPE_VARIABLES, { code: item.ipDnType }), 'label', '');
        }
      } else {
        return _.get(_.find(IP_DN_TYPE_VARIABLES, { code: item.ipDnType }), 'label', '');
      }
    },
    // 添付ファイルダウンロード
    attachDownload(item) {
      if (item.isAddIpDn) {
        // 紐付け前
        _.forEach(item.attachmentFiles, doc => {
          const query = { attachLinkId: doc.attachLinkId };
          const fileName = doc.attachDocumentName;
          // BFF_GMC06 添付ファイル出力BFF
          const apiCode = 'get_/v1/gm-pool-datas/attach/download';
          $api
            .txtDownload(query, fileName, 'tw-pooldata-bff-api', apiCode)
            .then(() => {})
            .catch(err => {
              this.$store.dispatch('SHOW_ALERT', err.message);
            });
        });
      } else {
        // 紐付け後
        _.forEach(item.attachmentFiles, doc => {
          const path = {
            documentManageId: _.get(doc, 'documentManageId'),
            documentTrx: _.get(doc, 'documentTrx')
          };
          const documentName = _.get(doc, 'documentName');
          $api
            .download(path, documentName)
            .then(() => {})
            .catch(err => {
              this.$store.dispatch('SHOW_ALERT', err.message);
            });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ip_dn_info {
  display: flex;
  // align-items: center;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  color: $color_gray_800;
  .head {
    display: flex;
    align-items: center;
    background: $color_gray_300;
    border-radius: 6px 0px 0px 6px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    padding: 0 16px;
    width: 131px;
  }
  .body {
    background: #ffffff;
    border: 1px solid $color_gray_300;
    border-radius: 0px 6px 6px 0px;
    width: 100%;
    max-height: 480px;
    overflow-y: scroll;
  }
  .inner {
    display: flex;
    padding: 20px;
    height: auto;
    .user {
      display: flex;
      margin-right: 32px;
      font-size: 14px;
      line-height: 20px;
      color: $color_black;
      .from_name {
        width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .info {
      display: flex;
      .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 16px;
        left: 0px;
        top: 0px;
        background: #ff7c9c;
        color: $color_white;
        border-radius: 15.5px;
        font-size: 10px;
        font-weight: bold;
        margin: 2px 12px 0 0;
      }
    }
    .right {
      margin-left: auto;
      display: flex;
    }
    .permit_download {
      margin: 0 32px 0 auto;
      display: flex;
      align-items: center;
      .pdf {
        margin: 0;
        cursor: pointer;
        > img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .delete {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 16px 0 auto;
      width: 40px;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
</style>
